<template>
    <div>
        <el-dialog title="上传资源" :visible.sync="dialogUploadResources" @close="onClose" fullscreen class="upload-resources-dialog">
            <div class="upload-resources">
                <div class="upload-types clearfix">
                    <div class="types-left">
                        <div class="item">
                            <span class="item-txt">分类选择：</span>
                            <el-cascader v-model="specializedValue" style="width: 300px" @change="changeResourcesClass" size="medium" :props="keyObj" :options="specializedList" clearable placeholder="请选择分类"></el-cascader>
                        </div>
                        <div class="item">
                            <span class="item-txt">资源类型：</span>
                            <!--<el-select v-model="resourcesValue" placeholder="请选择" size="medium" @change="supportFunc">
                                <el-option v-for="resourceItem in resourcesType" :key="resourceItem.resource_type_id" :label="resourceItem.resource_type_name" :value="resourceItem.resource_type_id"></el-option>
                            </el-select>-->
                            <el-cascader v-model="resourcesValue" :options="resourcesType" :props="resTypeProp" clearable @change="supportFunc" placeholder="请选择"></el-cascader>
                            <span class="item-txt-tip" v-show="supportTypeDesc">{{supportTypeDesc}}</span>
                        </div>
                    </div>
                    <div class="types-right">
                        <el-button type="primary" class="upload-btn" size="medium">
                            <span>选择文件</span>
                            <input type="file" :accept="acceptFormat" title="" multiple @change="chooseFile($event)" name='file'>
                        </el-button>
                    </div>
                </div>
                <div class="table-wrapper">
                    <el-table :data="resourcesUploadTable" border style="width: 100%" max-height="500" size="medium"
                              :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa'}"
                              class="table-content" :cell-style="{fontSize: '12px',color: '#333'}">
                        <el-table-column label="文件" width="200">
                            <template slot-scope="scope">
                                <span class="text-overflow-2" :title="scope.row.file_name">{{ scope.row.file_name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="上传进度" width="200" align="center">
                            <template slot-scope="scope">
                                <el-progress :text-inside="true" :stroke-width="14" :percentage="scope.row.process"></el-progress>
                            </template>
                        </el-table-column>
                        <el-table-column label="标题">
                            <template slot-scope="scope">
                                <i class="iconfont commit-required">*</i>
                                <!--:class="{'is-error' : errorInput}"-->
                                <el-input v-model="scope.row.resource_title" placeholder="请输入标题" size="medium"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="简介">
                            <template slot-scope="scope">
                                <i class="iconfont commit-required">*</i>
                                <el-input v-model="scope.row.resource_content" placeholder="请输入简介" size="medium"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="时长/页数" width="100" align="center">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.resource_page" size="medium"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100" align="center">
                            <template slot-scope="scope">
                                <el-button size="small" type="danger" @click="deleteUploadRow(scope.row.file_path, scope.$index)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="uploadFiles">上 传</el-button>
                <el-button type="primary" @click="saveResources">保 存</el-button>
                <el-button @click="dialogUploadResources = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import _ from "underscore";
    export default {
        data() {
            return {
                specializedValue: [],
                specializedList: [],
                keyObj: {
                    label: 'rc_name',
                    value: 'rc_id',
                    children: 'children',
                    // checkStrictly: true
                },
                resTypeProp: {
                    label: 'resource_type_name',
                    value: 'resource_type_id',
                    children: 'children'
                },
                resourcesType: [],
                libType: 0,
                resourcesValue: '',
                resourcesUploadTable: [],
                dialogUploadResources: false,
                supportTypeDesc: '',
                acceptFormat: '.pptx, .doc, .docx, .pdf, .mp4',
                uploadIndex: 0,
                materialTypeId: 0,
                // errorInput: 0
            }
        },
        created() {
            this.getSpecializedList();
            this.getResourcesType();
        },
        methods: {
            changeResourcesClass(value) {
                // console.log(value)
            },
            getSpecializedList() {
                this.$http.axiosGetBy(this.$api.resourceClassList, {type: 0}, (res) => {
                    if (res.code === 200) {
                        this.specializedList = this.getSpecializedListData(res.list);
                    }
                })
            },
            getSpecializedListData(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].children.length === 0) {
                        delete data[i].children;
                    } else {
                        this.getSpecializedListData(data[i].children)
                    }
                }
                return data;
            },
            getResourcesType() {
                this.$http.axiosGet(this.$api.resourceTypeList, (res) => {
                    if (res.code === 200) {
                        this.resourcesType = _.map(res.list, (item) => {
                            if (item.children) {
                                let childs = [];
                                _.each(item.children, (_val) => {
                                    if (Number(_val.type) === 0) {
                                        let tmp = {
                                            resource_type_id: _val.id,
                                            resource_type_name: _val.name
                                        }
                                        childs.push(tmp);
                                    }
                                });
                                item.children = childs;
                                return item;
                            } else {
                                item;
                            }
                            return item;
                        });
                    }
                })
            },
            uploadFiles() {
                let upFiles = this.resourcesUploadTable;
                let _this = this;
                if (upFiles.length === 0) {
                    this.$message.warning('请选择文件')
                } else {
                    for(let i = this.uploadIndex; i < upFiles.length; i++) {
                        this.uploadIndex++;
                        let formData = new FormData();
                        formData.append('file', upFiles[i].file);
                        let tmp = upFiles[i];
                        let config = {
                            onUploadProgress: progressEvent => {
                                let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                                tmp.process = complete;
                                _this.$set(_this.resourcesUploadTable, i, tmp);
                            }
                        };
                        this.$fileHttp.axiosFileConfig(this.$api.uploadFile, formData, config, (res) => {
                            if (res.code === 200) {
                                upFiles[i].file_name = res.data.file_name;
                                upFiles[i].file_path = res.data.file_path;
                                this.$message.success(res.msg);
                            } else {
                                this.$message.warning(res.msg);
                            }

                        }, (err) => {
                            console.log(err);
                            this.$message.error('上传文件太大');
                        })
                    }

                }
            },
            saveResources() {
                if (this.specializedValue.length < 2) {
                    this.$message.warning('分类请选择二级分类或者三级分类');
                    return
                }
                if (this.resourcesValue === '') {
                    this.$message.warning('请选择资源类型');
                    return
                }
                if (this.resourcesUploadTable.length === 0) {
                    this.$message.warning('请选择文件');
                    return
                }
                for (let i = 0; i < this.resourcesUploadTable.length; i++) {
                    if (this.resourcesUploadTable[i].resource_title === '') {
                        this.$message.warning('请填入标题');
                        return
                    }
                    if (this.resourcesUploadTable[i].resource_content === '') {
                        this.$message.warning('请输入简介');
                        return
                    }
                    if (!(this.resourcesUploadTable[i].process === 100)) {
                        this.$message.warning('请等待上传完成');
                        return
                    }
                }
                let formData = new FormData();
                formData.append('resource_type_id', this.resourcesValue[0]);
                if (this.resourcesValue.length > 1) {
                    formData.append('material_type_id', this.resourcesValue[1]);
                }
                formData.append('uploadList', JSON.stringify(this.resourcesUploadTable));
                formData.append('resource_class_id', this.specializedValue[this.specializedValue.length - 1]);
                formData.append('resource_type', this.libType);
                // formData.append('resource_type_id', this.resourcesValue);
                this.$http.axiosPost(this.$api.saveResource, formData, (res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            duration: 500,
                            message: res.msg,
                            onClose: ()=> {
                                this.$emit('materialMethod');
                                this.specializedValue = [];
                                this.resourcesValue = '';
                                this.resourcesUploadTable = [];
                                this.dialogUploadResources = false;
                            }
                        })
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            chooseFile(event) {
                let fileList = event.target.files;
                for (let i = 0; i < fileList.length; i++) {
                    if (fileList[i].size < 1024 * 1024 * 1024 * 2) {
                        let fileNameStr = fileList[i].name.split('.');
                        fileNameStr.pop();
                        let fileName = fileNameStr.join('.')
                        this.resourcesUploadTable.push({
                            file: fileList[i],
                            file_name: fileList[i].name,
                            process: 0,
                            resource_title: fileName,
                            resource_content: fileName,
                            resource_page: ''
                        })
                    } else {
                        this.$message.warning(fileList[i].name + '文件超过2G')
                    }
                }
                event.target.value = '';
            },
            deleteUploadRow(file_path, index) {
                console.log(file_path);
                if (this.uploadIndex) {
                    this.uploadIndex--;
                }
                if (file_path === undefined) {
                    this.resourcesUploadTable.splice(index, 1);
                } else {
                    this.$http.axiosGetBy(this.$api.delFile, {file_path: file_path}, (res) => {
                        if (res.code === 200) {
                            this.resourcesUploadTable.splice(index, 1);
                        }
                    })
                }
                console.log(this.uploadIndex);
            },
            supportFunc() {
                let type = Number(this.resourcesValue[0]);
                switch (type) {
                    case 1:
                        this.supportTypeDesc = '*支持上传资源格式：pptx/ppt/pdf';
                        this.acceptFormat = '.pptx, .ppt, .pdf';
                        break;
                    case 2:
                        this.supportTypeDesc = '*支持上传资源格式：doc/docx/pdf';
                        this.acceptFormat = '.doc, .docx, .pdf';
                        break;
                    case 3:
                        this.supportTypeDesc = '*支持上传资源格式：mp4';
                        this.acceptFormat = '.mp4';
                        break;
                    case 4:
                        this.supportTypeDesc = '*支持上传资源格式：mp4';
                        this.acceptFormat = '.mp4';
                        break;
                    case 5:
                        this.supportTypeDesc = '*支持上传资源格式：doc/docx/pdf/mp4/rar/zip';
                        this.acceptFormat = '.doc, .docx, .pdf, .mp4, .rar, .zip';
                        break;
                }
            },
            onClose() {
                this.specializedValue = [];
                this.resourcesValue = '';
                this.resourcesUploadTable = [];
                this.supportTypeDesc = '';
                this.uploadIndex = 0;
                this.acceptFormat = '.pptx, .doc, .docx, .pdf, .mp4';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .upload-resources-dialog {
        ::v-deep .el-dialog {
            .el-dialog__footer {
                text-align: center;
                .el-button+.el-button {
                    margin-left: 30px;
                }
            }
        }
    }
    .upload-resources {
        .upload-types {
            .types-left {
                float: left;
                .item {
                    display: inline-block;
                    margin-right: 20px;
                    .item-txt {
                        margin-right: 6px;
                    }
                }
            }
            .types-right {
                float: right;
                .upload-btn {
                    position: relative;
                    input {
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 100%;
                        opacity: 0;
                        cursor: pointer;
                    }
                }
            }
        }
        .table-wrapper {
            margin-top: 10px;
            .input-content {
                height: 30px;
                width: 100%;
            }
        }
    }
    .commit-required {
        width: 4%;
        color: #f56c6c;
        display: inline-block;
        & + .el-input {
            width: 96%;
        }
    }
    ::v-deep .el-input.is-error {
        .el-input__inner {
            border-color: #f56c6c;
        }
    }
    .item-txt-tip {
        color: #F56C6C;
        margin-left: 10px;
        font-size: 12px;
    }
</style>

<template>
    <div>
        <el-dialog  :visible.sync="status" title="添加资源" @open="initData" @close="closeForm" :before-close="closeForm">
            <el-form v-if="editingRow" :model="editingRow" :rules="rules" ref="editingRow" label-width="120px" class="demo-ruleForm">
                <el-form-item label="资源标题" prop="resource_title">
                    <el-input v-model="editingRow.resource_title"></el-input>
                </el-form-item>
                <el-form-item label="简介" prop="resource_content">
                    <el-input type="textarea" v-model="editingRow.resource_content"></el-input>
                </el-form-item>
                <el-form-item label="时长/页数" prop="duration">
                    <el-input v-model="editingRow.resource_page" style="width: 194px;"></el-input>
                </el-form-item>
                <el-form-item label="资源分类" prop="">
                    <el-cascader v-model="editingRow.resource_class" style="width: 300px" :props="keyObj" :options="specializedList" clearable placeholder="请选择分类"></el-cascader>
                </el-form-item>
                <el-form-item label="资源类型" prop="resources_value">
                    <el-cascader v-model="editingRow.resources_value" :options="resourcesType" :props="resTypeProp" @change="changeResourceType" clearable placeholder="请选择"></el-cascader>
                </el-form-item>
                <el-form-item label="资源线上链接" prop="url">
                    <el-input v-model="editingRow.url" type="textarea" :row="3" placeholder="请输入资源线上链接"></el-input>
                </el-form-item>

                <el-form-item style="margin-top: 42px;">
                    <el-button @click="closeForm">取 消</el-button>
                    <el-button type="primary" @click="submitForm('editingRow')">保 存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import _ from "underscore";
import {addResources} from "@/utils/apis";

export default {
    name: "ResourceAddForm",
    props: {
        status: Boolean
    },
    data() {
        return {
            editingRow: {
                resource_title: "",
                resource_content: "",
                resource_page: "",
                resources_value: "",
                resource_class: '',
                url: ""
            },
            specializedList: [],
            resourcesType: [],
            rules: {
                resource_title: [
                    { required: true, message: '请输入资源标题', trigger: 'blur' }
                ],
                resource_content: [
                    { required: true, message: '请输入简介', trigger: 'blur' }
                ],
                resource_class: [
                    { required: true, message: '请选择分类', trigger: 'change' }
                ],
                resources_value: [
                    { required: true, message: '请选择资源类型', trigger: 'change' }
                ],
                url: [
                    { required: true, message: '资源线上链接', trigger: 'blur' },
                    { type: "url", message: "请填写规范的线上网址", trigger: "blur"}
                ]
            },
            resTypeProp: {
                label: 'resource_type_name',
                value: 'resource_type_id',
                children: 'children'
            },
            keyObj: {
                label: 'rc_name',
                value: 'rc_id',
                children: 'children',
                // checkStrictly: true
            },
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    addResources(this.editingRow).then(res => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.$emit("close-act", 1);
                        }
                    })
                }
            })

        },
        closeForm() {
            this.$refs["editingRow"].resetFields()
            this.editingRow.resource_page = ""
            this.editingRow.resource_class = []
            this.$emit("close-act", false);
        },
        changeResourceType(val) {
            this.editingRow.resource_type_id = val[0];
            this.editingRow.material_type_id = (val.length === 2) ? val[1] : 0;
        },
        initData() {
            this.getResourcesTypeList()
            this.getSpecializedList()
        },
        getSpecializedList() {
            this.$http.axiosGetBy(this.$api.resourceClassList, { type: 0 },(res) => {
                if (res.code === 200) {
                    this.specializedList = this.getSpecializedListData(res.list);
                }
            }, (err) => {
                console.log(err);
            });
        },
        getSpecializedListData(data) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].children.length === 0) {
                    delete data[i].children;
                } else {
                    this.getSpecializedListData(data[i].children);
                }
            }
            return data;
        },
        getResourcesTypeList() {
            this.$http.axiosGet(this.$api.resourceTypeList, (res) => {
                if (res.code === 200) {
                    this.resourcesType = _.map(res.list, (item) => {
                        if (item.children) {
                            let childs = [];
                            _.each(item.children, (_val) => {
                                if (Number(_val.type) === 0) {
                                    let tmp = {
                                        resource_type_id: _val.id,
                                        resource_type_name: _val.name
                                    }
                                    childs.push(tmp);
                                }
                            });
                            item.children = childs;
                            return item;
                        } else {
                            item;
                        }
                        return item;
                    });
                }
            })
        },
    }
}
</script>

<style scoped lang="scss">

</style>
<template>
    <div class="resources-content">
        <div class="select-wrapper">
            <div class="select-left">
                <el-cascader v-model="specializedValue" style="width: 300px" :props="keyObj" :options="specializedList" @change="changeResourcesClass" clearable placeholder="请选择分类"></el-cascader>
            </div>
            <div class="select-btn">
                <el-button type="success" @click="addResource">添加资源</el-button>
                <el-button type="primary" @click="uploadResourcesBtn">
                    <i class="el-icon--left iconfont">&#xe8bc;</i>上传资源
                </el-button>
            </div>
        </div>
        <div class="type-wrapper">
            <div class="type-name">资源类型：</div>
            <div class="type-list">
                <div class="type-item" :class="{current:typeCurrent === 0}" @click="toggleTypeCur(0)">全部</div>
                <div class="type-item" :class="{current:typeCurrent === resourcesItem.resource_type_id}" v-for="resourcesItem in resourcesTypeList" :key="resourcesItem.resource_type_id" @click="toggleTypeCur(resourcesItem.resource_type_id)">{{resourcesItem.resource_type_name}}</div>
            </div>
        </div>
<!--        <div class="table-wrapper">-->
        <el-table :data="resourcesTableData" border style="width: 100%; margin-top: 20px; flex: 1;" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa'}" class="table-content" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column label="类型" width="80" align="center">
                <template slot-scope="scope">
                    <span :title="scope.row.resource_file_type">{{ scope.row.resource_file_type }}</span>
                </template>
            </el-table-column>
            <el-table-column label="资源标题">
                <template slot-scope="scope">
                    <span class="text-overflow-2" :title="scope.row.resource_title">{{ scope.row.resource_title }}</span>
                </template>
            </el-table-column>
            <el-table-column label="简介">
                <template slot-scope="scope">
                    <span class="text-overflow-2" :title="scope.row.resource_content">{{ scope.row.resource_content }}</span>
                </template>
            </el-table-column>
            <el-table-column label="时长/页数" width="100" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.resource_page }}</span>
                </template>
            </el-table-column>
            <el-table-column label="上传人" width="100" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.user_name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="上传时间" width="100" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.create_time }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="300" align="center">
                <template slot-scope="scope">
                    <el-button size="small" @click="previewResources(scope.row)">预览</el-button>
                    <el-button size="small" @click="downloadResources(scope.row)">下载</el-button>
                    <el-button size="small" type="primary" @click="editResources(scope.row, scope.$index)">编辑</el-button>
                    <el-button size="small" type="danger" @click="deleteRow(scope.row.resource_id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-right" :current-page="currentPage" layout="prev, pager, next, jumper" :total="totalPages" @current-change="handleCurrentChange">
        </el-pagination>

        <DialogUploadResources ref="uploadFilesDialog" @materialMethod="getResourcesList"></DialogUploadResources>
        <div class="video-dialog" v-if="showDialogMp4">
            <i class="iconfont close-icon" @click="closeDialogMp4">&#xe8e7;</i>
            <div class="video-content">
                <video width="100%" height="auto" :src="videoSrc" controls="true" controlslist="nodownload"></video>
            </div>
        </div>

        <el-dialog title="编辑资源" :visible.sync="dialogFormVisible" width="40%" :close-on-click-modal="false">
            <el-form v-if="editingRow" :model="editingRow" :rules="rules" ref="editingRow" label-width="100px" class="demo-ruleForm">
                <el-form-item label="资源标题" prop="resource_title">
                    <el-input v-model="editingRow.resource_title"></el-input>
                </el-form-item>
                <el-form-item label="简介" prop="resource_content">
                    <el-input type="textarea" v-model="editingRow.resource_content"></el-input>
                </el-form-item>
                <el-form-item label="时长/页数" prop="duration">
                    <el-input v-model="editingRow.resource_page" style="width: 194px;"></el-input>
                </el-form-item>
                <el-form-item label="资源" prop="file_preview_path">
                    <el-button type="primary" v-model="editingRow.file_preview_path" class="upload-btn" size="medium">
                        <span>选择文件</span>
                        <input type="file" :accept="'.' + editingRow.resource_file_type" title="" @change="changeFile($event)">
                    </el-button>
                    <div style="line-height: 1.5;">{{editingRow.file_name}}</div>
                </el-form-item>
                <el-form-item label="资源类型">
                    <el-cascader v-model="editingRow.resourcesValue" :options="resourcesType" :props="resTypeProp" @change="changeResourceType" clearable placeholder="请选择"></el-cascader>
                </el-form-item>
                <el-form-item style="margin-top: 42px;">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitForm('editingRow')">保 存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <resource-add-form :status.sync="state" @close-act="closeRes" />
    </div>
</template>

<script>
    import ResourceAddForm from "@/components/material/subjectType/ResourceAddForm.vue";
    import DialogUploadResources from 'components/materialUpload/DialogUploadResources';
    import _ from "underscore";

    export default {
        data() {
            return {
                state: false,
                specializedValue: [],
                specializedList: [],
                keyObj: {
                    label: 'rc_name',
                    value: 'rc_id',
                    children: 'children',
                    // checkStrictly: true
                },
                typeCurrent: 0,
                resourcesTableData: [],
                page: 1,
                currentPage: 1,
                totalPages: 0,
                showDialogMp4: false,
                videoSrc: '',

                dialogFormVisible: false,
                editingRow: null,
                resourcesTypeList: [],
                formLabelWidth: '120px',
                rules: {
                    resource_title: [
                        { required: true, message: '请输入资源标题', trigger: 'blur' }
                    ],
                    resource_content: [
                        { required: true, message: '请输入简介', trigger: 'blur' }
                    ],
                    file_preview_path: [
                        { required: true, message: '请选择文件', trigger: 'change' }
                    ],
                    resourcesValue: [
                        { required: true, message: '请选择资源类型', trigger: 'change' }
                    ],
                },
                editCurrentIndex: null,
                uploadDialogStatus: false,
                //资源类型
                resTypeProp: {
                    label: 'resource_type_name',
                    value: 'resource_type_id',
                    children: 'children'
                },
                resourcesType: [],
            }
        },
        components: {
            DialogUploadResources,
            ResourceAddForm
        },
        created() {
            this.getSpecializedList();
            this.getResourcesType();
            this.getResourcesList();
        },
        methods: {
            closeRes(val) {
                if (val === 1) {
                    this.state = false
                    this.getResourcesList()
                } else {
                    this.state = val
                }

            },
            addResource() {
                this.state = true
            },
            changeResourceType(val) {
                this.editingRow.resource_type_id = val[0];
                this.editingRow.material_type_id = (val.length === 2) ? val[1] : 0;
            },
            getSpecializedList() {
                this.$http.axiosGetBy(this.$api.resourceClassList, { type: 0 },(res) => {
                    if (res.code === 200) {
                        this.specializedList = this.getSpecializedListData(res.list);
                    }
                }, (err) => {
                    console.log(err);
                });
            },
            getSpecializedListData(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].children.length === 0) {
                        delete data[i].children;
                    } else {
                        this.getSpecializedListData(data[i].children);
                    }
                }
                return data;
            },
            getResourcesType() {
                this.$http.axiosGet(this.$api.resourceTypeList, (res) => {
                    if (res.code === 200) {
                        this.resourcesTypeList = res.list
                    }
                })
            },
            changeResourcesClass() {
                this.getResourcesList();
            },
            getResourcesList() {
                let resource_class_id = '';
                let specializedValueLength = this.specializedValue.length;
                if (specializedValueLength > 0) {
                    resource_class_id = this.specializedValue[specializedValueLength - 1];
                }
                // let resource_type_id = this.typeCurrent;
                let data = {
                    resource_type: 0,
                    resource_class_id: resource_class_id,
                    page: this.page
                }
                if (this.typeCurrent) {
                    data.resource_type_id = this.typeCurrent;
                }
                this.$http.axiosGetBy(this.$api.resourceList, data, (res) => {
                    if (res.code === 200) {
                        this.totalPages = res.total;
                        this.resourcesTableData = res.list;
                        if (this.totalPages && this.resourcesTableData.length === 0) {
                            this.page--;
                            this.getResourcesList();
                        }
                    }
                })
            },
            uploadResourcesBtn() {
                this.$refs.uploadFilesDialog.dialogUploadResources = true;
            },
            toggleTypeCur(id) {
                this.typeCurrent = id;
                this.getResourcesList();
            },
            deleteRow(id) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.resourceDelete, {resource_id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getResourcesList();
                        } else {
                            this.$message.error(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            previewResources(row) {
                if (row.resource_file_type === 'mp4' || row.resource_file_type === 'MP4') {
                    this.showDialogMp4 = true;
                    this.videoSrc = row.file_preview_path;
                } else {
                    let url = process.env.NODE_ENV === 'development' ? this.$config.baseUrl : '';
                    window.open(url + this.$api.pdfPreview + row.resource_id);
                }
            },
            closeDialogMp4() {
                this.showDialogMp4 = false
            },
            downloadResources(row) {
                this.$http.axiosGetBy(this.$api.download, {id: row.resource_id}, (res) => {
                    if (res.code === 200) {
                        window.location.href = res.url;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            editResources(row, index) {
                this.dialogFormVisible = true;
                this.editingRow = JSON.parse(JSON.stringify(row));
                this.editCurrentIndex = index;
                this.editingRow.resourcesValue = row.resource_type_id;
                if (row.resource_type_id === 5) {
                    if (row.material_type_id > 0) {
                        this.editingRow.material_type_id = row.material_type_id;
                        let arr = [];
                        arr.push(row.resource_type_id);
                        arr.push(row.material_type_id);
                        this.editingRow.resourcesValue = arr;
                    }
                }
                this.getResourcesTypeList();
            },
            getResourcesTypeList() {
                this.$http.axiosGet(this.$api.resourceTypeList, (res) => {
                    if (res.code === 200) {
                        this.resourcesType = _.map(res.list, (item) => {
                            if (item.children) {
                                let childs = [];
                                _.each(item.children, (_val) => {
                                    if (Number(_val.type) === 0) {
                                        let tmp = {
                                            resource_type_id: _val.id,
                                            resource_type_name: _val.name
                                        }
                                        childs.push(tmp);
                                    }
                                });
                                item.children = childs;
                                return item;
                            } else {
                                item;
                            }
                            return item;
                        });
                    }
                })
            },
            changeFile(event) {
                let fileList = event.target.files;
                console.log(fileList)
                if (fileList[0].size < 1024*1024*1024*2) {
                    this.editingRow.file_name = fileList[0].name;
                    this.editingRow.file_preview_path = fileList[0];
                } else {
                    this.$message.warning(fileList[0].name + '文件超过2G')
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('resource_id', this.editingRow.resource_id);
                        formData.append('resource_title', this.editingRow.resource_title);
                        formData.append('resource_content', this.editingRow.resource_content);
                        formData.append('resource_page', this.editingRow.resource_page);
                        formData.append('file', this.editingRow.file_preview_path);
                        formData.append('resource_type_id', this.editingRow.resource_type_id);
                        formData.append("material_type_id", this.editingRow.material_type_id);
                        this.$http.axiosPost(this.$api.editSave, formData, (res) => {
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    duration: 500,
                                    message: res.msg,
                                    onClose: ()=> {
                                        this.editingRow.resource_file_type = res.file_type;
                                        this.editingRow.file_preview_path = res.file_preview_path;
                                        this.$set(this.resourcesTableData, this.editCurrentIndex, this.editingRow);
                                        this.getResourcesList();
                                    }
                                })
                            } else {
                                this.$message.warning(res.msg);
                            }
                        }, (err) => {
                            console.log(err)
                        });
                        this.dialogFormVisible = false;
                    } else {
                        return false;
                    }
                })
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getResourcesList()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .select-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .type-wrapper {
        margin-top: 10px;
        display: flex;
        .type-name {
            width: 100px;
            margin-top: 10px;
            line-height: 30px;
            color: #000;
        }
        .type-list {
            .type-item {
                display: inline-block;
                padding: 4px 10px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 4px;
                margin: 10px 10px 0 0;
                font-size: 14px;
                border: 1px solid transparent;
                cursor: pointer;
                color: #666;
                transition: all .3s ease-in;
                &.current {
                    color: #005469;
                    background: #cfdfe3;
                    border: 1px solid #d9ecff;
                }
                &:hover {
                    color: #2e3d52;
                }
            }
        }
    }
    .table-content {
        .img-thumb {
            width: 60px;
            height: 60px;
            overflow: hidden;
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    .video-dialog {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.5);
        z-index: 9;
        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 30px;
            width: 30px;
            height: 30px;
            line-height: 1;
            background: rgba(0,0,0,.7);
            color: #fff;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: #409EFF;
            }
        }
        .video-content {
            width: 50%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 3px solid #111;
            video {
                outline: none;
                display: block;
            }
        }
    }
    .upload-btn {
        position: relative;
        input {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
</style>

<template>
    <div class="main-wrapper">
        <div class="subject-header">
            <div class="select-left">
                <div class="select class-select">
                    <span class="select-text">专业分类：</span>
                    <el-cascader v-model="classValue" style="width: 200px" :props="keyObj" :options="classList" @change="changeClass" clearable placeholder="请选择分类"></el-cascader>
                </div>
                <div class="select subject-type">
                    <span class="select-text">题型：</span>
                    <el-select v-model="subjectTypeValve" placeholder="请选择" style="width: 150px" clearable @change="changeType">
                        <el-option
                                v-for="item in subjectTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="search-btn">
                    <el-input placeholder="请输入内容" v-model="searchInput" >
                        <el-button slot="append" icon="el-icon-search" @click="changeSearch"></el-button>
                    </el-input>
                </div>
            </div>
            <div class="select-left">
                <el-dropdown @command="handleCommand">
                    <el-button type="primary"><i class="el-icon-plus"></i> 添加试题</el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="0">添加试题</el-dropdown-item>
                        <el-dropdown-item command="1">导入试题</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="subject-content">
            <el-table :data="subjectListTable" class="subject-table" style="width: 100%; flex: 1" height="1%"  size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa', textAlign: 'center'}" :cell-style="{fontSize: '12px',color: '#333'}">
                <el-table-column prop="topic" label="题文">
                    <template slot-scope="scope">
                        <span v-html="getTitle(scope.row.topic)"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="topic_tag" label="标签" width="180" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.tag_name | getTags}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="topic_type" label="题型" width="80" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.topic_type | getType}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="160">
                    <template slot-scope="scope">
                        <el-button size="small" @click="editSubject(scope.row, scope.row.id)">编辑</el-button>
                        <el-button size="small" @click="deleteSubject(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="pages-center"
                    :current-page="subjectPages.currentPageNum"
                    :page-size="subjectPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="subjectPages.total"
                    @current-change="subjectCurrentChange">
            </el-pagination>
        </div>
        <el-dialog
                title="导入试题模板"
                :visible.sync="importDialogStatus"
                width="30%"
                @close="handleClose">
            <div class="upload-file-content">
                <el-button type="primary" class="upload-btn" size="medium">
                    <span class="file-txt">选择文件</span>
                    <input type="file" accept=".xls, .xlsx" title="" class="file-input" multiple @change="chooseFile($event)" name='file' ref="fileInput">
                </el-button>
                <div class="downloadTemp" style="float: right;">
                    <a class="theme-hostFontColor" :href="tmplUrl" download="试题模板">模板下载</a>
                </div>
                <div class="file-list">
                    <ul class="files">
                        <li class="file" v-for="(item, index) in files" :key="index">
                            {{item.name}}
                        </li>
                    </ul>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
		        <el-button @click="handleClose">取 消</el-button>
		        <el-button type="primary" @click="confirmImport">确 定</el-button>
		    </span>
        </el-dialog>
    </div>
</template>

<script>
    import _ from 'underscore';
    export default {
        name: "MaterialExamSubject",
        created() {
            this.getSpecializedList();
            this.getSubjectList();
            this.downloadTmpl();
        },
        data() {
            return {
                subjectTab: '',
                //分类选择
                classValue: [],
                classList: [],
                keyObj: {
                    label: 'rc_name',
                    value: 'rc_id',
                    children: 'children'
                },
                //题型选择
                subjectTypeValve: [],
                subjectTypeList: [
                    {
                        value: '1',
                        label: '单选题'
                    }, {
                        value: '2',
                        label: '多选题'
                    }, {
                        value: '3',
                        label: '判断题'
                    }, {
                        value: '4',
                        label: '填空题'
                    }, {
                        value: '5',
                        label: '问答题'
                    }
                ],
                //搜索
                searchInput: '',
                //试题列表
                subjectListTable: [],
                //分页
                subjectPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                importDialogStatus: false,
                files: [],
                tmplUrl: ''
            }
        },
        //过滤器
        filters: {
            getType(val) {
                switch (val) {
                    case 1:
                        return '单选题';
                        break;
                    case 2:
                        return '多选题';
                        break;
                    case 3:
                        return '判断题';
                        break;
                    case 4:
                        return '填空题';
                        break;
                    case 5:
                        return '问答题';
                        break;
                }
            },
            getTags(val) {
                let tags = _.values(val)
                return tags.join();
            }
        },
        methods: {
            downloadTmpl() {
                this.$http.axiosGet(this.$api.exam_topictemplate, (res) => {
                    if (res.code === 200) {
                        this.tmplUrl = res.data.url;
                    }
                }, (err) => {
                    this.$common.axiosErrorMsg(err);
                })
            },
            handleCommand(command) {
                if (command === '0') {
                    this.$router.push('/admin/index/addSubject');
                } else if (command === '1') {
                    this.importDialogStatus = !this.importDialogStatus;
                }
            },
            chooseFile(event) {
                if (this.files.length) {
                    this.files = [];
                }
                let fileName = event.target.files[0].name;
                let limiteFileType = ["xls", "xlsx"];
                if (
                    !limiteFileType.includes(
                        fileName.split(".")[fileName.split(".").length - 1]
                    )
                ) {
                    this.$message.warning("文件格式错误！");
                    return;
                }
                this.files.push(event.target.files[0]);
                console.log(this.files,8888)
            },
            confirmImport() {
                let formData = new FormData;
                formData.append('excel', this.files[0]);
                this.$http.axiosFile(this.$api.getExcel, formData, (res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1000,
                            onClose: () => {
                                this.importDialogStatus = false;
                            }
                        });
                    } else {
                        this.$message({
                            type: 'warning',
                            message: res.msg,
                            duration: 1000
                        });
                    }
                }, (err) => {
                    console.log(err);
                });
            },
            handleClose() {
                this.files = [];
                this.importDialogStatus = false;
                this.$refs.fileInput.value = "";
            },
            //分类选择
            changeClass() {
                this.getSubjectList();
            },
            //题型选择
            changeType() {
                this.getSubjectList();
            },
            //搜索
            changeSearch() {
                this.getSubjectList();
            },
            //编辑试题
            editSubject(row, id) {
                //获取试题id
                this.$router.push({
                    path: '/admin/index/editSubject',
                    query: {id : id}
                });
            },
            //删除试题
            deleteSubject(id) {
                this.$confirm('是否删除该试题，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.delTopic, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getSubjectList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            //分页
            subjectCurrentChange(val) {
                this.subjectPages.currentPageNum = val;
                this.getSubjectList();
            },
            //专业分类
            getSpecializedList() {
                this.$http.axiosGetBy(this.$api.resourceClassList, {type: 1}, (res) => {
                    if (res.code === 200) {
                        this.classList = this.getSpecializedListData(res.list);
                    }
                })
            },
            getSpecializedListData(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].children.length === 0) {
                        delete data[i].children;
                    } else {
                        this.getSpecializedListData(data[i].children)
                    }
                }
                return data;
            },
            //试题列表
            getSubjectList() {
                let params = {
                    page: this.subjectPages.currentPageNum,
                    limit: this.subjectPages.eachPageNum
                }
                if (this.searchInput) {
                    params.topic = this.searchInput
                }
                if (this.classValue[this.classValue.length - 1]) {
                    params.rc_id = this.classValue[this.classValue.length - 1]
                }
                if (this.subjectTypeValve) {
                    params.topic_type = this.subjectTypeValve;
                }
                this.$http.axiosGetBy(this.$api.showTopic, params,(res) => {
                    if (res.code === 200) {
                        this.subjectListTable = res.data.data;
                        this.subjectPages.total = res.data.total;
                    }
                },(err) => {
                    console.log(err)
                })
            },
            //过虑
            getTitle(row) {
                let question = JSON.parse(row);
                return question.title;
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
    }
    .subject-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        .select-left {
            display: flex;
            justify-content: start;
            align-items: center;
            .select {
                padding-right: 10px;
            }
        }
    }
    .subject-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: calc(100% - 61px);
    }
    .el-pagination {
        padding: 10px 5px 0;
    }
    .upload-file-content {
        .upload-btn {
            position: relative;

            .file-txt {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20px;
                width: 80px;
            }

            .file-input {
                opacity: 0;
                width: 120px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .file-list {
            margin: 10px 0;

            ul, li {
                margin: 0;
                padding: 0;
            }

            .files {
                .file {
                    list-style: none;
                    padding-left: 10px;
                }
            }
        }
    }
</style>